import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useInView } from 'react-intersection-observer';
import {
  Box, Container, Image, useColorModeValue, Input, InputGroup, InputLeftElement,
  Text, HStack, VStack, Flex, Spinner, keyframes, Badge
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaSearch } from 'react-icons/fa';
import { getKeywords, getGalleryPhotos } from '../../api';
import { IKeyword, IPhoto, IExample } from '../../types';

const MotionBox = motion(Box);

interface ExtendedPhoto extends IPhoto {
  searchTerms: string[];
  keywordPk?: string;
  keywordText: string;
  exampleContent: string | null;
  category: string;
  description: string;
}

const PhotoCard: React.FC<{ photo: ExtendedPhoto; onClick: () => void }> = ({ photo, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const overlayBg = useColorModeValue('rgba(0,0,0,0.6)', 'rgba(255,255,255,0.6)');
  const textColor = useColorModeValue('white', 'black');
  
  const badgeBaseColor = useColorModeValue('white', 'gray.700');
  const badgeTextColor = useColorModeValue('gray.700', 'gray.100');

  return (
    <Box
      mb={4}
      onClick={onClick}
      cursor="pointer"
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Image
        src={photo.file}
        alt={photo.description}
        borderRadius="lg"
        objectFit="cover"
        w="100%"
      />
      <AnimatePresence>
        {isHovered && (
          <MotionBox
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            bg={overlayBg}
            height="120px"
            px={4}
            py={3}
            borderBottomRadius="lg"
          >
            <VStack align="start" spacing={2} height="100%" justifyContent="space-between">
              <Box>
                <Text fontWeight="bold" fontSize="md" color={textColor}>
                  {photo.keywordText}
                </Text>
                <Text mt={1} color={textColor} fontSize="sm" noOfLines={2}>
                  {photo.exampleContent || photo.description}
                </Text>
              </Box>
              <HStack spacing={2}>
                <Badge 
                  bg={badgeBaseColor}
                  color={badgeTextColor}
                  fontSize="2xs"
                  px={2}
                  py={0.5}
                  borderRadius="full"
                  letterSpacing="0.5px"
                  fontWeight="medium"
                >
                  #{photo.category}
                </Badge>
                <Badge 
                  bg={badgeBaseColor}
                  color={badgeTextColor}
                  fontSize="2xs"
                  px={2}
                  py={0.5}
                  borderRadius="full"
                  letterSpacing="0.5px"
                  fontWeight="medium"
                >
                  #{photo.exampleContent ? '프롬프트' : '키워드'}
                </Badge>
              </HStack>
            </VStack>
          </MotionBox>
        )}
      </AnimatePresence>
    </Box>
  );
};

const pulseAnimation = keyframes`
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
`;

const INITIAL_ITEMS_PER_PAGE = 20; // 한 페이지당 이미지 개수
const SUBSEQUENT_ITEMS_PER_PAGE = 15; // 로딩(스크롤) 이후 이미지 개수 

export default function Gallery(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const bgColor = useColorModeValue('white', 'gray.800');
  const searchBgColor = useColorModeValue('gray.50', 'gray.700');
  const searchTextColor = useColorModeValue('gray.600', 'gray.300');
  const [searchTerm, setSearchTerm] = useState('');
  const [initialLoad, setInitialLoad] = useState(true);
  const [fetchedIds, setFetchedIds] = useState<string[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
    rootMargin: '200px',
  });

  const shuffleArray = useCallback(<T extends unknown>(array: T[]): T[] => {
    if (!initialLoad) return array;
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  }, [initialLoad]);

  const mapPhotosToExtended = useCallback((photos: IPhoto[], keyword: IKeyword): ExtendedPhoto[] => {
    return photos.map(photo => {
      const matchingExample = keyword.examples.find(ex => ex.pk === photo.example);
      return {
        ...photo,
        searchTerms: [
          keyword.english_term,
          keyword.korean_term,
          keyword.description,
          keyword.category.name,
          matchingExample ? matchingExample.content : ''
        ],
        keywordPk: keyword.pk.toString(),
        keywordText: keyword.english_term,
        exampleContent: matchingExample ? matchingExample.content : null,
        category: keyword.category.name,
        description: keyword.description
      };
    });
  }, []);

  const fetchPhotos = useCallback(async ({ pageParam = 1 }) => {
    const isInitialFetch = pageParam === 1;
    const itemsPerPage = isInitialFetch ? INITIAL_ITEMS_PER_PAGE : SUBSEQUENT_ITEMS_PER_PAGE;
  
    if (!isInitialFetch) {
      await new Promise(resolve => setTimeout(resolve, 2000));
    }
  
    const keywords = await getKeywords();
    let photosData: ExtendedPhoto[] = [];
    let newFetchedIds: string[] = [];
  
    for (const keyword of keywords) {
      if (photosData.length >= itemsPerPage) break;
  
      const remainingItems = itemsPerPage - photosData.length;
      const response = await getGalleryPhotos(
        keyword.pk.toString(),
        remainingItems.toString(),
        pageParam,
        fetchedIds
      );
      const photos = response.results;
      newFetchedIds = [...newFetchedIds, ...response.new_fetched_ids];
      
      const newPhotos = mapPhotosToExtended(photos, keyword);
      
      photosData = [...photosData, ...newPhotos.slice(0, remainingItems)];
    }
  
    setFetchedIds(prevIds => [...prevIds, ...newFetchedIds]);
  
    return {
      photos: shuffleArray(photosData),
      nextPage: photosData.length > 0 ? pageParam + 1 : undefined,
    };
  }, [shuffleArray, fetchedIds, mapPhotosToExtended]);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(['photos'], fetchPhotos, {
    getNextPageParam: (lastPage) => lastPage.nextPage,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (location.state && (location.state as { scrollPosition: number }).scrollPosition) {
      window.scrollTo(0, (location.state as { scrollPosition: number }).scrollPosition);
      setInitialLoad(false);
    } else {
      setInitialLoad(true);
    }
  }, [location]);

  const filteredPhotos = useMemo(() => {
    if (!data) return [];
    const allPhotos = data.pages.flatMap(page => page.photos);
    return searchTerm
      ? allPhotos.filter(photo =>
        photo.searchTerms.some(term =>
          term.toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
      : allPhotos;
  }, [data, searchTerm]);

  const LoadingMessage = () => (
    <Flex
      direction="column"
      align="center"
      justify="center"
      height="50vh"
      textAlign="center"
    >
      <Box
        as={motion.div}
        animation={`${pulseAnimation} 1.5s ease-in-out infinite`}
        mb={4}
      >
        <Spinner size="xl" color="blue.500" thickness="4px" />
      </Box>
      <Text fontSize="xl" fontWeight="bold" mb={2}>
        이미지를 불러오는 중입니다
      </Text>
      <Text fontSize="md" color="gray.500">
        잠시만 기다려 주세요!
      </Text>
    </Flex>
  );

  return (
    <Box bg={bgColor} minHeight="100vh" py={8} ref={containerRef}>
      <Container maxW={{ base: "95%", xl: "80%" }} px={4}>
        <InputGroup mb={12} maxW="400px" mx="auto">
          <InputLeftElement pointerEvents="none" h="100%">
            <FaSearch color={searchTextColor} />
          </InputLeftElement>
          <Input
            placeholder="검색"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            bg={searchBgColor}
            color={searchTextColor}
            borderRadius="full"
            size="md"
            height="50px"
            pl="40px"
            _placeholder={{ color: searchTextColor }}
            _focus={{ boxShadow: 'none', borderColor: searchTextColor }}
          />
        </InputGroup>

        <AnimatePresence>
          {isLoading ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <LoadingMessage />
            </motion.div>
          ) : filteredPhotos.length === 0 ? (
            <Text textAlign="center">
              {searchTerm ? '검색 결과가 없습니다.' : '표시할 이미지가 없습니다.'}
            </Text>
          ) : (
            <>
              <ResponsiveMasonry columnsCountBreakPoints={{ 0: 2, 480: 2, 768: 3, 992: 4, 1280: 5 }}>
                <Masonry gutter="15px">
                  {filteredPhotos.map((photo) => (
                    <PhotoCard
                      key={photo.pk}
                      photo={photo}
                      onClick={() => {
                        const scrollPosition = window.pageYOffset;
                        navigate(`/keywords/${photo.keywordPk}`, { state: { scrollPosition } });
                      }}
                    />
                  ))}
                </Masonry>
              </ResponsiveMasonry>
              {!searchTerm && hasNextPage && (
                <Flex justifyContent="center" mt={8} ref={inViewRef}>
                  {isFetchingNextPage && (
                    <Spinner size="lg" color="blue.500" />
                  )}
                </Flex>
              )}
            </>
          )}
        </AnimatePresence>
      </Container>
    </Box>
  );
}